
import Size from "../../../../viewmodel/core/base/Size"
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import TextAndCheckBoxCalculation from "./TextAndCheckBoxCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndCheckBoxCalculation extends TextAndCheckBoxCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();
       
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        } else {
            return
        }
        let checkboxContent = this.data.checkBoxContent;
        let size = this.includeImageAndTextCheckboxSize();

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let textMargin = this.getTextEdgeInsets()
        let iconPointOffsetX = textMargin.left;
        let iconPointOffsetY = textMargin.top;
        let startLeft = this.iconMargin + (this.data.type == MindElementType.MAIN_SUBJECT ? iconPointOffsetX * 0.75 : iconPointOffsetX * 0.5);

        let iconLeft = 0;
        let iconTop = 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {

            checkboxContent.x = startLeft + marginX;
            checkboxContent.y = ((size.getHeight()) - checkboxContent.height) / 2 + marginY;

            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;
            iconLeft = checkboxContent.x + checkboxContent.width + this.checkboxTextSpace;

            textMindElementContent.x = iconLeft + imageContent.width + this.iconTextSpace;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            checkboxContent.x = startLeft + marginX;
            checkboxContent.y = ((size.getHeight()) - checkboxContent.height) / 2 + marginY;
            
            textMindElementContent.x = checkboxContent.x + checkboxContent.width + this.checkboxTextSpace;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            iconLeft = textMindElementContent.x + textMindElementContent.width;
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2 + marginX;
            iconTop = marginY;

            checkboxContent.x = startLeft + marginX;            

            textMindElementContent.x = checkboxContent.x + checkboxContent.width + this.checkboxTextSpace;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + this.iconSpace;

            checkboxContent.y = textMindElementContent.y + textMindElementContent.height/2 - checkboxContent.height/2;

        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndImageAndCheckBoxCalculation
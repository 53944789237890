import Point from "../../../../viewmodel/core/base/Point"
import Size from "../../../../viewmodel/core/base/Size"
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import MindElementData from "../../../../viewmodel/mindelementdata/MindElementData"
import IconElementContent from "../../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import MindElementContent from "../../../../viewmodel/mindelementdata/mindcontent/MindElementContent"
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndIconAndCheckBoxCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return;
        }

        let checkBoxContent = this.data.checkBoxContent; 
        let size = this.includeIconAndTextAndCheckboxSize();
        let layoutType = this.getIconLayout();
        let iconSsize = this.caluleIconElementSize();
        let checkboxSize = this.caluleCheckBoxSize();
        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let textMargin = this.getTextEdgeInsets()
        let iconPointOffsetX = textMargin.left;
        let iconPointOffsetY = textMargin.top;

        let contentStart = (this.data.type == MindElementType.MAIN_SUBJECT ? iconPointOffsetX * 0.75 : iconPointOffsetX * 0.5)

        let iconElementContents = this.data.iconElementContents;
        
        let iconLeft = 0;
        let iconTop = 0;

        checkBoxContent.x = marginX + this.iconMargin + contentStart;        

        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            checkBoxContent.y = (size.getHeight() - checkboxSize.getHeight()) / 2 + marginY;  

            iconLeft = contentStart + this.iconMargin + checkboxSize.getWidth() + this.iconMargin;
            iconTop = (size.getHeight() - iconSsize.getHeight()) / 2;

            textMindElementContent.x = marginX + iconLeft + iconSsize.getWidth();// + this.iconTextSpace;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            checkBoxContent.y = (size.getHeight() - checkboxSize.getHeight()) / 2 + marginY;          

            textMindElementContent.x = checkBoxContent.x + checkboxSize.getWidth() + this.checkboxTextSpace;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            iconLeft = textMindElementContent.x + textMindElementContent.width + this.iconTextSpace - marginX;
            iconTop = (size.getHeight() - iconSsize.getHeight()) / 2;

        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {

            iconLeft = (size.getWidth() - iconSsize.getWidth()) / 2;
            iconTop = (this.iconSpace);

            textMindElementContent.x = checkBoxContent.x + checkBoxContent.width + this.checkboxTextSpace;
            textMindElementContent.y = iconTop + (iconSsize.getHeight()) + marginY;

            checkBoxContent.y = textMindElementContent.y + textMindElementContent.height / 2 - checkBoxContent.height/2;       
        }
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index - 1].x + iconElementContents[index - 1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndIconAndCheckBoxCalculation

import Point from "../core/base/Point"
import IdGenerator from "../core/base/IdGenerator"
import MindElementShapeType from "../datatype/MindElementShapeType.js"
import MindElementType from "../datatype/MindElementType.js"
import NodeLayoutType from "../datatype/NodeLayoutType.js"
import CGPoint from "../core/base/basedata/CGPoint"
import Util from "../../utils/Util"
import MindElementAlignmentType from "../datatype/MindElementAlignmentType"
import Strings from "../../utils/Strings"
import UiUtil from "../../utils/UiUtil"
import MindElementCalculation from "../../core/core/calcule/elementCalculation/MindElementCalculation"
import Config from "../../core/core/calcule/Config"
import CGRect from "../core/base/basedata/Rect"
import ConnectLineType from "../datatype/ConnectLineType"
import MindElementFullType from "../datatype/MindElementFullType"
import Colors from "../../utils/Colors"
import strings from "../../common/lang/strings"
import TextContent from "./mindcontent/TextContent"
import CornerShape from "../core/shape/CornerShape.js"
import MindTaskType from "../datatype/MindTaskType.js"


 class MindElementData {
    constructor() {
        this.type= MindElementType.SUBJECT,
        this.id = -1,
        this.x = -1,
        this.y = -1,
        this.outlineX = -1,
        this.outlineY = -1,
        this.width = 100,
        this.height = 36,
        this.backgroundColor = 0xEEE3B6,
        this.backgroundColorAlpha = 1.0,
        this.backgroundFullType = MindElementFullType.FULL,
        this.borderColor = 0xA6A6A6,
        this.borderColorAlpha = 1.0,
        this.borderWidth = 0,
        this.borderDot = false,
        this.borderThicken = false,
        this.styleColor = 0xA6A6A6 ,// 主线颜色
        this.styleFillColor = 0xEEE3B6,
        this.isSelected = false,
        this.level = 0,
        this.parentNodeId = -1,
        this.layout = NodeLayoutType.LAYOUT_RIGHT,

        this.isEdit = false,
        this.mindElementShape = MindElementShapeType.Corner,
        this.isHidden = false,
        this.isLayoutHidden = false,
        this.hiddenNumber = 0,
        
        //我觉得这里应该是new一个实例，不是直接把类赋值到这里
        this.latexContent = null,//new LatexContent(),
        this.textContent = null,//new TextContent(),
        this.titleContent = null,//new TextContent(),
        this.lineContent = null,//new lineContent(),
        this.imageContent = null,// new ImageContent(),
        this.generalizationContent = null,// new GeneralizationContent(),
        this.iconElementContents = null,// new IconElementContent(),
        this.linkElementContent = null, // new LinkElementContent(),
        this.remarksElementContent = null, // new RemarksElementContent(),
        this.connectMapElementContent = null, // new ConnectMapElementContent(),
        this.timeLineContent = null,// new TimeLineContent(),
        this.statisticsContent = null,
        this.mindElementOriginalShape = null,// MindElementShapeType.Corner,
        this.handwritingContent = null,
        this.checkBoxContent = null,
        this.isShadow = false,
        this.bubbleRadius = -1,
        this.moveAutoAdsorptionTime = -1,

        this.backgroundUrl = "",
        this.padding = 0,
        this.customWidth = 0,
        this.customHeight = 0,
        this.angle = 0,
        this.alignmentType = MindElementAlignmentType.LEFT,
        this.explain = ""
        this.isFreeLayoutNode = false;
        this.isFreeTreeNode = false
        this.taskContent = null;
        this.resourceContent = null;
        this.moreContent = null;
        this.orderContent = null;
        this.task = MindTaskType.NORMAL;
    }
    //js中用于深度克隆的函数
    deepClone(obj){
        if (obj === null) return null;
        let clone = Object.assign({}, obj);
        Object.keys(clone).forEach(
            key => (clone[key] = typeof obj[key] === 'object' ? this.deepClone(obj[key]) : obj[key])
        );
        return Array.isArray(obj) && obj.length
            ? (clone.length = obj.length) && Array.from(clone)
            : Array.isArray(obj)
            ? Array.from(obj)
            : clone;
    }
    isEmpty() {
        return this.id == IdGenerator.INVALID_ID;
    }
    //创建空的节点
    emptyMindNode() {
        return new MindElementData();
    }
    //当前时间线是否选中
    selected() {
        if (this.isSelected) {
            return true;
        }

        if (this.type == MindElementType.TIME_LINE_BASE_ELEMENT && this.timeLineContent != null) {
            for (let i = 0; i < this.timeLineContent.length; i++) {
                if (this.timeLineContent[i]) {
                    return true;
                }
            }
        }
        return false;
    }
    //拷贝样式
    copyStyle(target) {
        this.backgroundColor = target.backgroundColor;
        this.borderColor = target.borderColor;
        this.borderWidth = target.borderWidth;
        this.isShadow = target.isShadow;
        this.backgroundUrl = target.backgroundUrl;
        this.padding = target.padding;
        this.alignmentType = target.alignmentType;
        this.customWidth = target.customWidth;
        this.angle = target.angle
        this.customHeight = target.customHeight;
        this.explain = target.explain;
        this.backgroundColorAlpha = target.backgroundColorAlpha;
        this.borderColorAlpha = target.borderColorAlpha;
        this.borderDot = target.borderDot;
        this.borderThicken = target.borderThicken;
        this.backgroundFullType = target.backgroundFullType

        if (this.textContent != null && target.textContent != null) {
            this.textContent.textBold = target.textContent.textBold;
            this.textContent.textColor = target.textContent.textColor;
            this.textContent.textFontSize = target.textContent.textFontSize;
            this.textContent.textItalics = target.textContent.textItalics;
            this.textContent.textStrikethrough = target.textContent.textStrikethrough;
        }
        if (this.titleContent != null && target.titleContent != null) {
            this.titleContent.textBold = target.titleContent.textBold;
            this.titleContent.textColor = target.titleContent.textColor;
            this.titleContent.textFontSize = target.titleContent.textFontSize;
            this.titleContent.textItalics = target.titleContent.textItalics;
            this.titleContent.textStrikethrough = target.titleContent.textStrikethrough;
        }
        
        if (this.lineContent != null && target.lineContent != null) {
            this.lineContent.color = target.lineContent.color;
            this.lineContent.lineWidth = target.lineContent.lineWidth;
            this.lineContent.dottedLine = target.lineContent.dottedLine;
            this.lineContent.lineThicken = target.lineContent.lineThicken;
            this.lineContent.nodeConnectLineDottedLine = target.lineContent.nodeConnectLineDottedLine;
        }

        if (this.statisticsContent != null && target.statisticsContent != null) {
            this.statisticsContent.stickStyle(target.statisticsContent);
        }
        if (this.handwritingContent != null && target.handwritingContent != null) {
            this.handwritingContent.stickStyle(target.handwritingContent);
        }
        if (this.checkBoxContent != null && target.checkBoxContent != null) {
            this.checkBoxContent.stickStyle(target.checkBoxContent);
        }
    }

    isCardContent() {
        return this.titleContent != null && this.mindElementShape == MindElementShapeType.Card && 
        this.layout != NodeLayoutType.LAYOUT_CIRCLE && this.layout != NodeLayoutType.LAYOUT_FORM &&
        this.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL && this.layout != NodeLayoutType.LAYOUT_TRIANGLE;
    }

    isStatisticsContent() {
        return this.statisticsContent != null && this.statisticsContent.cellsData.length > 0;
    }

    isContainOrderContent() {
        return this.orderContent != null && this.orderContent.order.length > 0;
    }

    isOrderContent() {
        return this.orderContent != null && this.orderContent.order != null && this.orderContent.order.length > 0;
    }

    isContainImageContent() {
        return this.imageContent != null && 
            ((this.imageContent.key != null && this.imageContent.key.length > 0) || 
            (this.imageContent.videoUrl != null && this.imageContent.videoUrl.length > 0));
    }

    isContainLinkContent() {
        return this.linkElementContent != null && this.linkElementContent.links.length > 0;
    }

    isContainRemarksContent() {
        return this.remarksElementContent != null && this.remarksElementContent.text.length > 0;
    }

    isContainLatextContent() {
        return this.latexContent != null && this.latexContent.text.length > 0;
    }

    isContainResourceContent() {
        return this.resourceContent != null && this.resourceContent.url.length > 0;
    }

    isContainConnectMapContent() {
        let str = new Strings()
        return this.connectMapElementContent != null && !str.isEmpty(this.connectMapElementContent.uuid);
    }

    isContainExplainContent() {
        let str = new Strings()
        return !str.isEmpty(str.lineFeed(this.explain))
    }

    isHandwritingContent() {
        return this.handwritingContent != null
    }

    isCheckBoxContent() {
        return this.checkBoxContent != null
    }



    //拷贝节点
    copy() {
        let data = this.emptyMindNode();
        data.type = this.type;
        data.x = this.x;
        data.y = this.y;
        data.outlineX = this.outlineX;
        data.outlineY = this.outlineY;
        data.width = this.width;
        data.height = this.height;
        data.backgroundColor = this.backgroundColor;
        data.borderColor = this.borderColor;
        data.borderWidth = this.borderWidth;
        data.styleColor = this.styleColor;
        data.styleFillColor = this.styleFillColor;
        data.isSelected = false;
        data.id = this.id;
        data.level = this.level;
        data.parentNodeId = this.parentNodeId;
        data.isEdit = false;
        data.mindElementShape = this.mindElementShape;
        data.mindElementOriginalShape = this.mindElementOriginalShape;
        data.layout = this.layout;
        data.isHidden = this.isHidden;
        data.hiddenNumber = this.hiddenNumber;
        data.isShadow = this.isShadow;
        data.bubbleRadius = this.bubbleRadius;
        data.backgroundUrl = this.backgroundUrl;
        data.padding = this.padding;
        data.customWidth = this.customWidth;
        data.angle = this.angle;
        data.customHeight = this.customHeight;
        data.alignmentType = this.alignmentType;
        data.explain = this.explain;
        data.isFreeLayoutNode = this.isFreeLayoutNode;
        data.isFreeTreeNode = this.isFreeTreeNode;
        data.backgroundColorAlpha = this.backgroundColorAlpha;
        data.borderColorAlpha = this.borderColorAlpha;
        data.borderDot = this.borderDot;
        data.borderThicken = this.borderThicken;
        data.isLayoutHidden = this.isLayoutHidden;
        data.backgroundFullType = this.backgroundFullType;

        if (this.imageContent != null) {
            data.imageContent = this.imageContent.copy();
        }
        if (this.textContent != null) {
            data.textContent = this.textContent.copy();
        }
        if (this.titleContent != null) {
            data.titleContent = this.titleContent.copy();
        }
        if (this.lineContent != null) {
            data.lineContent = this.lineContent.copy();
        }
        if (this.generalizationContent != null) {
            data.generalizationContent = this.generalizationContent.copy();
        }
        if (this.timeLineContent != null) {
            data.timeLineContent = this.timeLineContent.copy();
        }

        if (this.orderContent != null) {
            data.orderContent = this.orderContent.copy();
        }

        if (this.iconElementContents != null) {
            let copyIconElementContents = new Array();
            let iconCount = this.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    copyIconElementContents.push(this.iconElementContents[index].copy());
                }
            }
            data.iconElementContents = copyIconElementContents;
        }
        if (this.linkElementContent != null) {
            data.linkElementContent = this.linkElementContent.copy();
        }
        if (this.remarksElementContent != null) {
            data.remarksElementContent = this.remarksElementContent.copy();
        }
        if (this.connectMapElementContent != null) {
            data.connectMapElementContent = this.connectMapElementContent.copy();
        }

        if (this.statisticsContent != null) {
            data.statisticsContent = this.statisticsContent.copy();
        }
        if (this.taskContent != null) {
            data.taskContent = this.taskContent.copy();
        }
        if (this.latexContent != null) {
            data.latexContent = this.latexContent.copy();
        }
        
        if (this.resourceContent != null) {
            data.resourceContent = this.resourceContent.copy();
        }
        if (this.moreContent != null) {
            data.moreContent = this.moreContent.copy();
        }
        if (this.handwritingContent != null) {
            data.handwritingContent = this.handwritingContent.copy();
        }
        if (this.checkBoxContent != null) {
            data.checkBoxContent = this.checkBoxContent.copy();
        }
        
        return data;
    }

    //粘贴
    stick(data, ignorePoint = false) {
        this.type = data.type;
        if (!ignorePoint) {
            this.x = data.x;
            this.y = data.y;
            this.outlineX = data.outlineX;
            this.outlineY = data.outlineY;
        }
        
        this.width = data.width;
        this.height = data.height;
        this.backgroundColor = data.backgroundColor;
        this.borderColor = data.borderColor;
        this.borderWidth = data.borderWidth;
        this.styleColor = data.styleColor;
        this.styleFillColor = data.styleFillColor;
        this.isSelected = false;
        this.id = data.id;
        this.level = data.level;
        this.parentNodeId = data.parentNodeId;
        this.isEdit = false;
        this.mindElementShape = data.mindElementShape;
        this.mindElementOriginalShape = data.mindElementOriginalShape;
        this.layout = data.layout;
        this.isHidden = data.isHidden;
        this.hiddenNumber = data.hiddenNumber;
        this.isShadow = data.isShadow;
        this.bubbleRadius = data.bubbleRadius;
        this.backgroundUrl = data.backgroundUrl;
        this.padding = data.padding;
        this.customWidth = data.customWidth;
        this.angle = data.angle;
        this.customHeight = data.customHeight;
        this.alignmentType = data.alignmentType;
        this.explain = data.explain;
        this.isFreeLayoutNode = data.isFreeLayoutNode;
        this.isFreeTreeNode = data.isFreeTreeNode;
        this.backgroundColorAlpha = data.backgroundColorAlpha;
        this.borderColorAlpha = data.borderColorAlpha;
        this.borderDot = data.borderDot;
        this.borderThicken = data.borderThicken;
        this.isLayoutHidden = data.isLayoutHidden;
        this.backgroundFullType = data.backgroundFullType;

        if (data.imageContent != null) {
            this.imageContent = data.imageContent.copy();
        }
        if (data.textContent != null) {
            this.textContent = data.textContent.copy();
        }
        if (data.titleContent != null) {
            this.titleContent = data.titleContent.copy();
        }
        if (data.lineContent != null) {
            this.lineContent = data.lineContent.copy();
        }
        if (data.generalizationContent != null) {
            this.generalizationContent = data.generalizationContent.copy();
        }
        if (data.timeLineContent != null) {
            this.timeLineContent = data.timeLineContent.copy();
        }
        if (data.orderContent != null) {
            this.orderContent = data.orderContent.copy();
        }
        if (data.iconElementContents != null) {
            let copyIconElementContents = new Array();
            let iconCount = data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    copyIconElementContents.push(data.iconElementContents[index].copy());
                }
            }
            this.iconElementContents = copyIconElementContents;
        }
        if (data.linkElementContent != null) {
            this.linkElementContent = data.linkElementContent.copy();
        }
        if (data.remarksElementContent != null) {
            this.remarksElementContent = data.remarksElementContent.copy();
        }
        if (data.connectMapElementContent != null) {
            this.connectMapElementContent = data.connectMapElementContent.copy();
        }
        if (data.taskContent != null) {
            this.taskContent = data.taskContent.copy();
        }
        
        if (data.resourceContent != null) {
            this.resourceContent = data.resourceContent.copy();
        }
        if (data.moreContent != null) {
            this.moreContent = data.moreContent.copy();
        }
        if (data.handwritingContent != null) {
            this.handwritingContent = data.handwritingContent.copy();
        }
        if (data.checkBoxContent != null) {
            this.checkBoxContent = data.checkBoxContent.copy();
        }
        
    }

    //粘贴样式
    stickStyle(data) {
        if (data.isEmpty()) {
            return;
        }

        this.backgroundColor = data.backgroundColor;
        this.borderColor = data.borderColor;
        this.borderWidth = data.borderWidth;
        this.styleColor = data.styleColor;
        this.styleFillColor = data.styleFillColor;
        this.mindElementShape = data.mindElementShape;
        this.mindElementOriginalShape = data.mindElementOriginalShape;
        this.isShadow = data.isShadow;
        this.bubbleRadius = data.bubbleRadius;
        this.backgroundUrl = data.backgroundUrl;
        this.padding = data.padding;
        this.alignmentType = data.alignmentType;
        this.customHeight = data.customHeight;
        this.customWidth = data.customWidth;
        this.angle = data.angle;
        this.backgroundColorAlpha = data.backgroundColorAlpha;
        this.borderColorAlpha = data.borderColorAlpha;
        this.borderDot = data.borderDot;
        this.backgroundFullType = data.backgroundFullType;
        if (this.textContent != null) {
            this.textContent.stickStyle(data.textContent);
            this.textContent.stickStyle(data.generalizationContent);
        }
        if (this.titleContent != null) {
            this.titleContent.stickStyle(data.titleContent);
        }
        if (this.generalizationContent != null) {
            this.generalizationContent.stickStyle(data.textContent);
            this.generalizationContent.stickStyle(data.generalizationContent);
        }
        if (this.imageContent != null) {
            this.imageContent.stickStyle(data.imageContent);
        }
        if (this.lineContent != null) {
            this.lineContent.stickStyle(data.lineContent);
        }
        if (this.timeLineContent != null) {
            this.timeLineContent.stickStyle(data.timeLineContent);
        }
        if (this.iconElementContents != null && this.iconElementContents.length > 0 &&
                data.iconElementContents != null && data.iconElementContents.length > 0) {

            for (let i = 0; i < this.iconElementContents.length; i++) {
                this.iconElementContents[i].stickStyle(data.iconElementContents[0]);
            }
        }
        if (this.statisticsContent != null) {
            this.statisticsContent.stickStyle(data.statisticsContent);
        }
        this.checkShapeCard()
        if (this.isCardContent() && this.borderColor == this.backgroundColor) {
            this.borderColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.backgroundColor, 0.5));
        }

        if (this.handwritingContent != null) {
            this.handwritingContent.stickStyle(data.handwritingContent);
        }
        if (this.checkBoxContent != null) {
            this.checkBoxContent.stickStyle(data.checkBoxContent);
        }
        
    }
    stickColorStyle( data) {
        if (data.isEmpty()) {
            return;
        }

        this.backgroundColor = data.backgroundColor;
        this.borderColor = data.borderColor;
        this.styleColor = data.styleColor;
        this.styleFillColor = data.styleFillColor;
        this.isShadow = data.isShadow;
        this.backgroundColorAlpha = data.backgroundColorAlpha;
        this.borderColorAlpha = data.borderColorAlpha;
        this.backgroundFullType = data.backgroundFullType;

        if (this.textContent != null) {
            this.textContent.stickColorStyle(data.textContent);
            this.textContent.stickColorStyle(data.generalizationContent);
        }
        if (this.titleContent != null) {
            this.titleContent.stickColorStyle(data.titleContent);
        }
        
        if (this.generalizationContent != null) {
            this.generalizationContent.stickColorStyle(data.textContent);
            this.generalizationContent.stickColorStyle(data.generalizationContent);
        }
        if (this.lineContent != null) {
            this.lineContent.stickColorStyle(data.lineContent);
        }
        if (this.timeLineContent != null) {
            this.timeLineContent.stickColorStyle(data.timeLineContent);
        }
        if (this.statisticsContent != null) {
            this.statisticsContent.stickColorStyle(data.statisticsContent);
        }

        if (this.isCardContent() && this.borderColor == this.backgroundColor) {
            this.borderColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.backgroundColor, 0.5));
        }

        if (this.handwritingContent != null) {
            this.handwritingContent.stickColorStyle(data.handwritingContent);
        }

        if (this.checkBoxContent != null) {
            this.checkBoxContent.stickColorStyle(data.checkBoxContent);
        }
        
    }
    //判断节点是否相同
    equal(data, ignorePoint = false, ignoreSize = false, 
        ignoreInsidePoint = false, ignoreInsideSize = false, ignoreIsEdit = false) {
        let result = false;
        result = result || data.type != this.type;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
            result = result || data.outlineX != this.outlineX;
            result = result || data.outlineY != this.outlineY;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        if (!Colors.isClear(data.backgroundColor) || !Colors.isClear(this.backgroundColor)) {
            result = result || data.backgroundColor != this.backgroundColor;
        }
        if (!Colors.isClear(data.borderColor) || !Colors.isClear(this.borderColor)) {
            result = result || data.borderColor != this.borderColor;
        }
        if (!Colors.isClear(data.styleFillColor) || !Colors.isClear(this.styleFillColor)) {
            result = result || data.styleFillColor != this.styleFillColor;
        }
        result = result || data.borderWidth != this.borderWidth;
        result = result || data.styleColor != this.styleColor;
        result = result || data.level != this.level;
        result = result || data.parentNodeId != this.parentNodeId;
        if (!ignoreIsEdit) {
            result = result || data.isEdit != this.isEdit;
            result = result || data.isSelected != this.isSelected;
        }
        result = result || data.mindElementShape != this.mindElementShape;
        result = result || data.layout != this.layout;
        result = result || data.isHidden != this.isHidden;
        result = result || data.hiddenNumber != this.hiddenNumber;
        result = result || data.isShadow != this.isShadow;
        result = result || data.bubbleRadius != this.bubbleRadius;
        result = result || data.backgroundUrl != this.backgroundUrl;
        result = result || data.padding != this.padding;
        
        result = result || data.customWidth != this.customWidth;
        result = result || data.angle != this.angle;        
        result = result || data.customHeight != this.customHeight;
        result = result || data.alignmentType != this.alignmentType;
        result = result || data.explain != this.explain;
        result = result || data.isFreeLayoutNode != this.isFreeLayoutNode;
        result = result || data.isFreeTreeNode != this.isFreeTreeNode;
        result = result || data.backgroundColorAlpha != this.backgroundColorAlpha;
        result = result || data.borderColorAlpha != this.borderColorAlpha;
        result = result || data.borderDot != this.borderDot;
        result = result || data.borderThicken != this.borderThicken;
        result = result || data.isLayoutHidden != this.isLayoutHidden;
        result = result || data.backgroundFullType != this.backgroundFullType;
        
        result = result || data.imageContent != null && this.imageContent == null;
        result = result || data.imageContent == null && this.imageContent != null;
        if (!result && data.imageContent != null && this.imageContent != null) {
            result = result || !data.imageContent.equal(this.imageContent, ignoreInsidePoint);
        }
        
        result = result || data.textContent != null && this.textContent == null;
        result = result || data.textContent == null && this.textContent != null;
        if (!result && data.textContent != null && this.textContent != null) {
            result = result || !data.textContent.equal(this.textContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.titleContent != null && this.titleContent == null;
        result = result || data.titleContent == null && this.titleContent != null;
        if (!result && data.titleContent != null && this.titleContent != null) {
            result = result || !data.titleContent.equal(this.titleContent, ignoreInsidePoint, ignoreInsideSize);
        }
        // if (result) {
        //     console.log("data ~**~ ", result, this.textContent.text, data.textContent.text, this, data);
        // }
        result = result || data.lineContent != null && this.lineContent == null;
        result = result || data.lineContent == null && this.lineContent != null;
        if (!result && data.lineContent != null && this.lineContent != null) {
            result = result || !data.lineContent.equal(this.lineContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.orderContent != null && this.orderContent == null;
        result = result || data.orderContent == null && this.orderContent != null;
        if (!result && data.orderContent != null && this.orderContent != null) {
            result = result || !data.orderContent.equal(this.orderContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.generalizationContent != null && this.generalizationContent == null;
        result = result || data.generalizationContent == null && this.generalizationContent != null;
        if (!result && data.generalizationContent != null && this.generalizationContent != null) {
            result = result || !data.generalizationContent.equal(this.generalizationContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.timeLineContent != null && this.timeLineContent == null;
        result = result || data.timeLineContent == null && this.timeLineContent != null;
        if (!result && data.timeLineContent != null && this.timeLineContent != null) {
            result = result || !data.timeLineContent.equal(this.timeLineContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.linkElementContent != null && this.linkElementContent == null;
        result = result || data.linkElementContent == null && this.linkElementContent != null;
        if (!result && data.linkElementContent != null && this.linkElementContent != null) {
            result = result || !data.linkElementContent.equal(this.linkElementContent, ignoreInsidePoint, ignoreInsideSize);
        }

        result = result || data.remarksElementContent != null && this.remarksElementContent == null;
        result = result || data.remarksElementContent == null && this.remarksElementContent != null;
        if (!result && data.remarksElementContent != null && this.remarksElementContent != null) {
            result = result || !data.remarksElementContent.equal(this.remarksElementContent, ignoreInsidePoint, ignoreInsideSize);
        }

        result = result || data.connectMapElementContent != null && this.connectMapElementContent == null;
        result = result || data.connectMapElementContent == null && this.connectMapElementContent != null;
        if (!result && data.connectMapElementContent != null && this.connectMapElementContent != null) {
            result = result || !data.connectMapElementContent.equal(this.connectMapElementContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.iconElementContents != null && this.iconElementContents == null;
        result = result || data.iconElementContents == null && this.iconElementContents != null;
        if (!result && data.iconElementContents != null && this.iconElementContents != null) {
            result = result || data.iconElementContents.length != this.iconElementContents.length;
        }

        if (!result && this.iconElementContents != null && data.iconElementContents != null) {
            let iconCount = this.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    result = result || !data.iconElementContents[index].equal(this.iconElementContents[index], ignoreInsidePoint, ignoreInsideSize);
                }
            }
        }
        result = result || data.statisticsContent != null && this.statisticsContent == null;
        result = result || data.statisticsContent == null && this.statisticsContent != null;
        if (!result && data.statisticsContent != null && this.statisticsContent != null) {
            result = result || !data.statisticsContent.equal(this.statisticsContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.taskContent != null && this.taskContent == null;
        result = result || data.taskContent == null && this.taskContent != null;
        if (!result && data.taskContent != null && this.taskContent != null) {
            result = result || !data.taskContent.equal(this.taskContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.latexContent != null && this.latexContent == null;
        result = result || data.latexContent == null && this.latexContent != null;
        if (!result && data.latexContent != null && this.latexContent != null) {
            result = result || !data.latexContent.equal(this.latexContent, ignoreInsidePoint, ignoreInsideSize);
        }
        result = result || data.resourceContent != null && this.resourceContent == null;
        result = result || data.resourceContent == null && this.resourceContent != null;
        if (!result && data.resourceContent != null && this.resourceContent != null) {
            result = result || !data.resourceContent.equal(this.resourceContent, ignoreInsidePoint, ignoreInsideSize);
        }

        result = result || data.moreContent != null && this.moreContent == null;
        result = result || data.moreContent == null && this.moreContent != null;
        if (!result && data.moreContent != null && this.moreContent != null) {
            result = result || !data.moreContent.equal(this.moreContent, ignoreInsidePoint, ignoreInsideSize);
        }

        result = result || data.handwritingContent != null && this.handwritingContent == null;
        result = result || data.handwritingContent == null && this.handwritingContent != null;
        if (!result && data.handwritingContent != null && this.handwritingContent != null) {
            result = result || !data.handwritingContent.equal(this.handwritingContent, ignoreInsidePoint, ignoreInsideSize);
        }

        result = result || data.checkBoxContent != null && this.checkBoxContent == null;
        result = result || data.checkBoxContent == null && this.checkBoxContent != null;
        if (!result && data.checkBoxContent != null && this.checkBoxContent != null) {
            result = result || !data.checkBoxContent.equal(this.checkBoxContent, ignoreInsidePoint, ignoreInsideSize);
        }        
        return !result;
    }

    //节点是边框是否为矩形
    findHitPointAndCenter(point, insidePoint = null) {
        switch (this.mindElementShape) {
            case MindElementShapeType.Circular:
                return this.getRightAngleShapeHitPoint(point, 3, insidePoint);
                default:
                    return this.getRightAngleShapeHitPoint(point, 3, insidePoint);
        }
    }
    //计算节点中心点
    getCenterPoint() {
        return new Point(this.x + this.width / 2, this.y + this.height / 2);
    }

    getRightAngleShapeHitPoint(point, margin, insidePoint = null) {
        if (this.lineContent != null && this.type == MindElementType.NODE_CONNECT_LINE) {
            return this.getRightAngleShapeHitPointByConnectLine(point);
        } else if (this.mindElementShape == MindElementShapeType.Diamond) {
            return this.getRightAngleShapeHitPointByDiamond(point, margin, insidePoint);
        } else if (this.mindElementShape == MindElementShapeType.Circular ||
            this.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.mindElementShape == MindElementShapeType.Ring ||
            this.mindElementShape == MindElementShapeType.Ring3 ||
            this.mindElementShape == MindElementShapeType.Ring2 ||
            this.mindElementShape == MindElementShapeType.Star ||
            this.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.mindElementShape == MindElementShapeType.Book ||
            this.mindElementShape == MindElementShapeType.Octagonal ||
            this.mindElementShape == MindElementShapeType.Ring2_UP) {
            return this.getRightAngleShapeHitPointByCircular(point, margin, insidePoint);
        }
        let XWithMargin = this.x - margin;
        let YWithMargin = this.y - margin;
        let widthWithMargin = this.width + margin * 2;
        let heightWithMargin = this.height + margin * 2;
        if (Util.containsInRectForPoint(new CGRect(this.x, this.y, this.width, this.height), point)) {
            return point;
        }
        //CGPoint只引入了包，没有赋值在construct的变量中
        
        let centerPoint = new CGPoint(widthWithMargin / 2 + XWithMargin, heightWithMargin / 2 + YWithMargin);
        if (insidePoint != undefined && insidePoint != null && insidePoint.x != null) {
            centerPoint = insidePoint
        }
        //数据结构怎么变
        let selfLineTop= [new CGPoint(XWithMargin, YWithMargin),
        new CGPoint(widthWithMargin + XWithMargin, YWithMargin)];

        let selfLineRight = [
                            new CGPoint(widthWithMargin + XWithMargin, YWithMargin),
                            new CGPoint(widthWithMargin + XWithMargin, heightWithMargin + YWithMargin)];

        let selfLineBottom = [
                            new CGPoint(XWithMargin, heightWithMargin + YWithMargin),
                            new CGPoint(widthWithMargin + XWithMargin, heightWithMargin + YWithMargin)];
        let selfLineLeft = [
                            new CGPoint(XWithMargin, YWithMargin),
                            new CGPoint(XWithMargin, heightWithMargin + YWithMargin)];

        let topI = Util.getStaticgetLineIntersectionPoint(selfLineTop[0], selfLineTop[1], centerPoint, point);
        let rightI = Util.getStaticgetLineIntersectionPoint(selfLineRight[0], selfLineRight[1], centerPoint, point);
        let bottomI = Util.getStaticgetLineIntersectionPoint(selfLineBottom[0], selfLineBottom[1], centerPoint, point);
        let leftI = Util.getStaticgetLineIntersectionPoint(selfLineLeft[0], selfLineLeft[1], centerPoint, point);

        let spaceingWithCenterAndPoint = Util.getPointSpacing(centerPoint, point);

        let topISpaceingWithCenter = topI.x > -1 && topI.y > -1 ? Util.getPointSpacing(topI, centerPoint) : 20000;
        let topISpaceingWithPoint = topI.x > -1 && topI.y > -1 ? Util.getPointSpacing(topI, point) : 20000;

        let rightISpaceingWithCenter = rightI.x > -1 && rightI.y > -1 ? Util.getPointSpacing(rightI, centerPoint) : 20000;
        let rightISpaceingWithPoint = rightI.x > -1 && rightI.y > -1 ? Util.getPointSpacing(rightI, point) : 20000;

        let bottomISpaceingWithCenter = bottomI.x > -1 && bottomI.y > -1 ? Util.getPointSpacing(bottomI, centerPoint) : 20000;
        let bottomISpaceingWithPoint = bottomI.x > -1 && bottomI.y > -1 ? Util.getPointSpacing(bottomI, point) : 20000;

        let leftISpaceingWithCenter = leftI.x > -1 && leftI.y > -1 ? Util.getPointSpacing(leftI, centerPoint) : 20000;
        let leftISpaceingWithPoint = leftI.x > -1 && leftI.y > -1 ? Util.getPointSpacing(leftI, point) : 20000;

        let ignorValue = 1;

        let isTopMin = Math.abs(topISpaceingWithCenter + topISpaceingWithPoint - spaceingWithCenterAndPoint) < ignorValue;
        let isRightMin = Math.abs(rightISpaceingWithCenter + rightISpaceingWithPoint - spaceingWithCenterAndPoint) < ignorValue;
        let isLeftMin = Math.abs(leftISpaceingWithCenter + leftISpaceingWithPoint - spaceingWithCenterAndPoint) < ignorValue;
        let isBottomMin = Math.abs(bottomISpaceingWithCenter + bottomISpaceingWithPoint - spaceingWithCenterAndPoint) < ignorValue;
        if (isTopMin && isRightMin) {
            if (topISpaceingWithCenter < rightISpaceingWithCenter) {
                return topI;
            } else {
                return rightI;
            }
        } else if (isTopMin && isLeftMin) {
            if (topISpaceingWithCenter < leftISpaceingWithCenter) {
                return topI;
            } else {
                return leftI;
            }
        } else if (isBottomMin && isRightMin) {
            if (bottomISpaceingWithCenter < rightISpaceingWithCenter) {
                return bottomI;
            } else {
                return rightI;
            }
        } else if (isBottomMin && isLeftMin) {
            if (bottomISpaceingWithCenter < leftISpaceingWithCenter) {
                return bottomI;
            } else {
                return leftI;
            }
        } else if (isTopMin) {
            return topI;
        } else if (isRightMin) {
            return rightI;
        } else if (isBottomMin) {
            return bottomI;
        } else if (isLeftMin) {
            return leftI;
        }

        if (topISpaceingWithCenter == rightISpaceingWithCenter) {//对角线
            if (topISpaceingWithPoint < bottomISpaceingWithPoint) {
                return topI;
            } else {
                return bottomI;
            }
        } else if (topISpaceingWithCenter < rightISpaceingWithCenter  &&
            (topISpaceingWithPoint < spaceingWithCenterAndPoint || spaceingWithCenterAndPoint < topISpaceingWithCenter)) {
            return topI;
        } else if (rightISpaceingWithCenter < topISpaceingWithCenter  &&
            (rightISpaceingWithPoint < spaceingWithCenterAndPoint  || spaceingWithCenterAndPoint < rightISpaceingWithCenter)) {
            return rightI;
        } else if (bottomISpaceingWithCenter < leftISpaceingWithCenter  &&
            (bottomISpaceingWithPoint < spaceingWithCenterAndPoint || spaceingWithCenterAndPoint < bottomISpaceingWithCenter)) {
            return bottomI;
        } else {
            return leftI;
        }
    }

    getRightAngleShapeHitPointByCircular(point,margin, insidePoint = null) {
        if (this.mindElementShape != MindElementShapeType.Circular &&
            this.mindElementShape != MindElementShapeType.Circular_Right_Top &&
            this.mindElementShape != MindElementShapeType.Ring &&
            this.mindElementShape != MindElementShapeType.Ring2 &&
            this.mindElementShape != MindElementShapeType.Ring2_UP &&
            this.mindElementShape != MindElementShapeType.Ring3 &&
            this.mindElementShape != MindElementShapeType.Star &&
            this.mindElementShape != MindElementShapeType.Octagonal &&
            this.mindElementShape != MindElementShapeType.Regular_hexagon &&
            this.mindElementShape != MindElementShapeType.Book) {
            return new CGPoint();
        }
        let XWithMargin = this.x - margin;
        let YWithMargin = this.y - margin;
        let widthWithMargin = this.width + margin * 2;
        let heightWithMargin = this.height + margin * 2;

        let centerPoint = new CGPoint(widthWithMargin / 2 + XWithMargin,
                heightWithMargin / 2 + YWithMargin);

        let centerPointNoMargins = new CGPoint(this.width / 2 + this.x, this.height / 2 + this.y);
        let pointAndCenterPointSpacing = Util.getPointSpacing(centerPointNoMargins, point);
        if (pointAndCenterPointSpacing < this.width / 2) {
            return point;
        }
        let points = Util.getInsertPointBetweenCircleAndLine(point.x, point.y, centerPoint.x, centerPoint.y,
                centerPoint.x, centerPoint.y, widthWithMargin / 2);

        let arrFloat = new Array();
        let arrPoint = new Array();

        for (let i = 0; i < points.length; i++) {
            let dd = Util.getPointSpacing(point, points[i]);
            arrFloat.push(dd);
            arrPoint.push(points[i]);
        }
        if (arrPoint.isEmpty()) {
            return new CGPoint();
        }
        let minPoint = arrPoint[0];
        let minFloat = arrFloat[0];

        for (let index = 0; index < arrFloat.length; index++) {
            if (minFloat > arrFloat[index]) {
                minFloat = arrFloat[index];
                minPoint = arrPoint[index];
            }
        }
        return minPoint;
    }

    getRightAngleShapeHitPointByDiamond(point, margin, insidePoint = null) {
        if (this.mindElementShape != MindElementShapeType.Diamond) {
            return new CGPoint();
        }
        let XWithMargin = this.x - margin;
        let YWithMargin = this.y - margin;
        let widthWithMargin = this.width + margin * 2;
        let heightWithMargin = this.height + margin * 2;

        let centerPoint = new CGPoint(widthWithMargin / 2 + XWithMargin,
                heightWithMargin / 2 + YWithMargin);

        let selfLineTop = [new CGPoint(XWithMargin, YWithMargin + heightWithMargin / 2),
                        new CGPoint(widthWithMargin / 2 + XWithMargin, YWithMargin)];

        let selfLineRight = [new CGPoint(widthWithMargin / 2 + XWithMargin, YWithMargin),
                        new CGPoint(widthWithMargin + XWithMargin, heightWithMargin / 2 + YWithMargin)];

        let selfLineBottom = [new CGPoint(widthWithMargin + XWithMargin, heightWithMargin/2 + YWithMargin),
                        new CGPoint(widthWithMargin / 2 + XWithMargin, heightWithMargin + YWithMargin)];
        let selfLineLeft = [new CGPoint(widthWithMargin / 2 + XWithMargin, heightWithMargin + YWithMargin),
                        new CGPoint(XWithMargin, heightWithMargin / 2 + YWithMargin)];

        let topI = Util.getStaticgetLineIntersectionPoint(selfLineTop[0], selfLineTop[1], centerPoint, point);
        let rightI = Util.getStaticgetLineIntersectionPoint(selfLineRight[0], selfLineRight[1], centerPoint, point);
        let bottomI = Util.getStaticgetLineIntersectionPoint(selfLineBottom[0], selfLineBottom[1], centerPoint, point);
        let leftI = Util.getStaticgetLineIntersectionPoint(selfLineLeft[0], selfLineLeft[1], centerPoint, point);

        let spaceingWithCenterAndPoint = Util.getPointSpacing(centerPoint, point);

        let topISpaceingWithCenter = topI.x > -1 && topI.y > -1 ? Util.getPointSpacing(topI, centerPoint) : 20000;
        let topISpaceingWithPoint = topI.x > -1 && topI.y > -1 ? Util.getPointSpacing(topI, point) : 20000;

        let rightISpaceingWithCenter = rightI.x > -1 && rightI.y > -1 ? Util.getPointSpacing(rightI, centerPoint) : 20000;
        let rightISpaceingWithPoint = rightI.x > -1 && rightI.y > -1 ? Util.getPointSpacing(rightI, point) : 20000;

        let bottomISpaceingWithCenter = bottomI.x > -1 && bottomI.y > -1 ? Util.getPointSpacing(bottomI, centerPoint) : 20000;
        let bottomISpaceingWithPoint = bottomI.x > -1 && bottomI.y > -1 ? Util.getPointSpacing(bottomI, point) : 20000;

        let leftISpaceingWithCenter = leftI.x > -1 && leftI.y > -1 ? Util.getPointSpacing(leftI, centerPoint) : 20000;
        let leftISpaceingWithPoint = leftI.x > -1 && leftI.y > -1 ? Util.getPointSpacing(leftI, point) : 20000;

        let ignorValue = 1;

        let isTopMin = Math.abs(topISpaceingWithCenter + topISpaceingWithPoint - spaceingWithCenterAndPoint) < ignorValue;
        let isRightMin = Math.abs(rightISpaceingWithCenter + rightISpaceingWithPoint - spaceingWithCenterAndPoint) < ignorValue;
        let isLeftMin = Math.abs(leftISpaceingWithCenter + leftISpaceingWithPoint - spaceingWithCenterAndPoint) < ignorValue;
        let isBottomMin = Math.abs(bottomISpaceingWithCenter + bottomISpaceingWithPoint - spaceingWithCenterAndPoint) < ignorValue;
        if (isTopMin && isRightMin) {
            if (topISpaceingWithCenter < rightISpaceingWithCenter) {
                return topI;
            } else {
                return rightI;
            }
        } else if (isTopMin && isLeftMin) {
            if (topISpaceingWithCenter < leftISpaceingWithCenter) {
                return topI;
            } else {
                return leftI;
            }
        } else if (isBottomMin && isRightMin) {
            if (bottomISpaceingWithCenter < rightISpaceingWithCenter) {
                return bottomI;
            } else {
                return rightI;
            }
        } else if (isBottomMin && isLeftMin) {
            if (bottomISpaceingWithCenter < leftISpaceingWithCenter) {
                return bottomI;
            } else {
                return leftI;
            }
        } else if (isTopMin) {
            return topI;
        } else if (isRightMin) {
            return rightI;
        } else if (isBottomMin) {
            return bottomI;
        } else if (isLeftMin) {
            return leftI;
        }
        
        if (topISpaceingWithCenter == rightISpaceingWithCenter) {//对角线
            if (topISpaceingWithPoint < bottomISpaceingWithPoint) {
                return topI;
            } else {
                return bottomI;
            }
        } else if (topISpaceingWithCenter < rightISpaceingWithCenter  &&
                (topISpaceingWithPoint < spaceingWithCenterAndPoint || spaceingWithCenterAndPoint < topISpaceingWithCenter)) {
            return topI;
        } else if (rightISpaceingWithCenter < topISpaceingWithCenter  &&
                    (rightISpaceingWithPoint < spaceingWithCenterAndPoint  || spaceingWithCenterAndPoint < rightISpaceingWithCenter)) {
            return rightI;
        } else if (bottomISpaceingWithCenter < leftISpaceingWithCenter  &&
                    (bottomISpaceingWithPoint < spaceingWithCenterAndPoint || spaceingWithCenterAndPoint < bottomISpaceingWithCenter)) {
            return bottomI;
        } else {
            return leftI;
        }
    }

    getRightAngleShapeHitPointByConnectLine(point, margin = 10) {
        if (this.lineContent == null || this.type != MindElementType.NODE_CONNECT_LINE) {
            return new CGPoint();
        }
        let XWithMargin = this.x;
        let YWithMargin = this.y;

        let startPoint = this.lineContent.startPoint();
        let endPoint = this.lineContent.endPoint();
        let globalStartPoint = new CGPoint(startPoint.x + XWithMargin, startPoint.y + YWithMargin);
        let globalEndPoint = new CGPoint(endPoint.x + XWithMargin, endPoint.y + YWithMargin);
        var points = []
        switch (this.lineContent.connectLineType) {
            case ConnectLineType.STRAIGHT_ARROW_LINE:
            case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                let centerI = Util.getStaticgetLineSegmentIntersectionVerticalPoint(globalStartPoint , globalEndPoint, point);
                if (centerI.x <= 0 || centerI.y <= 0 ||
                        centerI.x > 900000 || centerI.y > 900000) {
                    if (margin <= 0 || Util.getPointSpacing(globalStartPoint, point) < margin) {
                        return globalStartPoint;
                    } else {
                        return new CGPoint(-1, -1);
                    }
                } else {
                    if (margin <= 0 || Util.getPointSpacing(centerI, point) < margin) {
                        return centerI;
                    } else {
                        return new CGPoint(-1, -1);
                    }
                }
            case ConnectLineType.CURVE_LINE:

                let startPointG = globalStartPoint;
                let endPointG = globalEndPoint;

                let precision = (Math.max(Math.abs(startPoint.x - endPoint.x), Math.abs(startPoint.y - endPoint.y)));
                precision = Math.min(precision, 100);
                let list = [];
                list.push(startPointG);
                list.push(this.lineContent.getStartControlPoint());
                list.push(this.lineContent.getEndControlPoint());
                list.push(endPointG);

                points = Util.bezierCalculate(list, precision);

                if (points.length > 0) {
                    let minDistance = 900000;
                    let intersectionPoint = startPoint;
                    for (let index = 0; index < points.length; index++) {
                        let d = Util.getPointSpacing(points[index], point);

                        if (minDistance > d) {
                            minDistance = d;
                            intersectionPoint = points[index];
                        }
                    }
                    if (margin <= 0 || Util.getPointSpacing(intersectionPoint, point) < margin) {
                        return intersectionPoint;
                    } else {
                        return new CGPoint(-1, -1);
                    }
                }
                if (margin <= 0 || Util.getPointSpacing(globalStartPoint, point) < margin) {
                    return globalStartPoint;
                } else {
                    return new CGPoint(-1, -1);
                }
            case ConnectLineType.RIGHT_ANGLE_LINE:
                points = [];
                points.push(globalStartPoint);
                let count = this.lineContent.rightAnglePoints.length
                if (count > 0) {
                    for (let index = 0; index < count; index++) {
                        points.push(new CGPoint(this.lineContent.rightAnglePoints[index].x + XWithMargin,
                                this.lineContent.rightAnglePoints[index].y + YWithMargin));
                    }
                }
                points.push(globalEndPoint);

                count = points.length
                let conformingPoint = []

                for (let index = 0; index < count-1; index++) {
                    let foot = Util.getStaticgetLineIntersectionVerticalPoint(points[index], points[index + 1], point);
                    let d = Util.getPointSpacing(points[index], points[index + 1]);
                    let d1 = Util.getPointSpacing(points[index], foot);
                    let d2 = Util.getPointSpacing(points[index + 1], foot);

                    if (d1 < d && d2 < d) {
                        conformingPoint.push(foot);
                    }
                }
                let conformingPointCount = conformingPoint.length
                if (conformingPointCount == 0) {
                    let minDistance = 900000;
                    let intersectionPoint = startPoint;
                    for (let index = 0; index < count; index++) {
                        let d = Util.getPointSpacing(points[index], point);

                        if (minDistance > d) {
                            minDistance = d;
                            intersectionPoint = points[index];
                        }
                    }
                    if (margin <= 0 || Util.getPointSpacing(intersectionPoint, point) < margin) {
                        return intersectionPoint;
                    } else {
                        return new CGPoint(-1, -1);
                    }
                } else if (conformingPointCount == 1) {
                    if (margin <= 0 || Util.getPointSpacing(conformingPoint[0], point) < margin) {
                        return conformingPoint[0];
                    } else {
                        return new CGPoint(-1, -1);
                    }
                } else {
                    let minDistance = 900000;
                    let intersectionPoint = conformingPoint[0];
                    for (let index = 0; index < conformingPointCount; index++) {
                        let d = Util.getPointSpacing(conformingPoint[index], point);

                        if (minDistance > d) {
                            minDistance = d;
                            intersectionPoint = conformingPoint[index];
                        }
                    }
                    if (margin <= 0 || Util.getPointSpacing(intersectionPoint, point) < margin) {
                        return intersectionPoint;
                    } else {
                        return new CGPoint(-1, -1);
                    }
                }

            default:
                if (margin <= 0 || Util.getPointSpacing(globalStartPoint, point) < margin) {
                    return globalStartPoint;
                } else {
                    return new CGPoint(-1, -1);
                }
        }
    }

    hitImage(point) {
        if (this.imageContent == null) {
            return false;
        }
        return (point.x) >= this.x + this.imageContent.x &&
                    (point.x) <= this.x + this.imageContent.x + this.imageContent.width &&
                    (point.y) >= this.y + this.imageContent.y &&
                    (point.y) <= this.y + this.imageContent.y + this.imageContent.height;
    }

    hitText( point) {
        if (this.textContent != null && !new Strings().isEmpty(this.textContent.text)) {
            return (point.x) >= this.x + this.textContent.x &&
                    (point.x) <= this.x + this.textContent.x + this.textContent.width &&
                    (point.y) >= this.y + this.textContent.y &&
                    (point.y) <= this.y + this.textContent.y + this.textContent.height;
        } else if (this.generalizationContent != null && !new Strings().isEmpty(this.generalizationContent.text)) {
            return (point.x) >= this.x + this.generalizationContent.x &&
                    (point.x) <= this.x + this.generalizationContent.x + this.generalizationContent.width &&
                    (point.y) >= this.y + this.generalizationContent.y &&
                    (point.y) <= this.y + this.generalizationContent.y + this.generalizationContent.height;
        }
        return false;
    }

    hitEncircleTitle( point) {
        if (this.type != MindElementType.BAOWEI_VIEW ||
            this.lineContent == null ||
            this.lineContent.textContent == null) {
            return false;
        }

        return point.x >= this.x + this.lineContent.textContent.x &&
                point.x <= this.x + this.lineContent.textContent.x + this.lineContent.textContent.width + new UiUtil().dip2px(10) &&
                point.y >= this.y + this.lineContent.textContent.y &&
                point.y <= this.y + this.lineContent.textContent.y + this.lineContent.textContent.height + new UiUtil().dip2px(6);
    }

    hitConnectTitle( point) {
        if (this.type != MindElementType.NODE_CONNECT_LINE ||
            this.lineContent == null ||
            this.lineContent.textContent == null) {
            return false;
        }
        let space = new UiUtil().dip2px(5);
        return point.x >= this.x + this.lineContent.textContent.x - space &&
                point.x <= this.x + this.lineContent.textContent.x + this.lineContent.textContent.width + space &&
                point.y >= this.y + this.lineContent.textContent.y - space &&
                point.y <= this.y + this.lineContent.textContent.y + this.lineContent.textContent.height + space;
    }

    hitPoint(point, margin = 0) {
        if (this.lineContent != null && this.type == MindElementType.NODE_CONNECT_LINE) {
            return this.getRightAngleShapeHitPointByConnectLine(point);
        }
        return new CornerShape(this, margin).hit(point)
    }

    hitAnchorPoint(point, margin = 0) {
        if (this.lineContent != null && this.type == MindElementType.NODE_CONNECT_LINE) {
            return this.getRightAngleShapeHitPointByConnectLine(point);
        }
        return new CornerShape(this, margin).hitAnchorPoint(point)
    }

    hitExplain( point) {
        if (!this.isContainExplainContent()) {
            return false;
        }
        if (point.x >= this.x && point.x <= this.x + this.width &&
                point.y >= this.y &&
                point.y <= this.y + this.height + new UiUtil().dip2px(25)) {
            let size = MindElementCalculation.caluleText(new Strings().lineFeed(this.explain), this.getNodeExplainFontSize(), false);
            // let rect = new CGRect();
            let rectRight;
            let rectLeft;
            let rectBottom;
            let rectTop;
            if (this.explainAlignmentType() == MindElementAlignmentType.MIDDLE) {
                rectLeft = this.x + (this.width - Math.min(size.getWidth(), this.width))/2;
            } else {
                rectLeft = this.x;
            }
            rectRight = rectLeft + Math.min(size.getWidth(), this.width);
            rectTop = this.y + this.height;
            rectBottom = this.y + this.height + size.getHeight() + new UiUtil().dip2px(8);
            return point.x >= rectLeft && point.x <= rectRight &&
                    point.y >= rectTop && point.y <= rectBottom;
        }
        return false;
    }

    explainAlignmentType() {
        if (this.mindElementShape == MindElementShapeType.Circular ||
            this.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.mindElementShape == MindElementShapeType.Ring ||
            this.mindElementShape == MindElementShapeType.Ring2 ||
            this.mindElementShape == MindElementShapeType.Ring2_UP ||
            this.mindElementShape == MindElementShapeType.Ring3 ||
            this.mindElementShape == MindElementShapeType.Ellipse ||
            this.mindElementShape == MindElementShapeType.Diamond ||
            this.mindElementShape == MindElementShapeType.InvertedTriangle ||
            this.mindElementShape == MindElementShapeType.Heart ||
            this.mindElementShape == MindElementShapeType.Star ||
            this.mindElementShape == MindElementShapeType.Octagonal ||
            this.mindElementShape == MindElementShapeType.Regular_hexagon ||
            this.mindElementShape == MindElementShapeType.Book ||
            this.mindElementShape == MindElementShapeType.Gemstone ||
            this.mindElementShape == MindElementShapeType.Shield) {
            return MindElementAlignmentType.MIDDLE;
        } else {
            return MindElementAlignmentType.LEFT;
        }
    }

    getRect() {
        if (this.isHidden) {
            return new CGRect(this.x, this.y, 0, 0)
        }
        return new CGRect(this.x, this.y, this.width, this.height)
    }

    getPadingLeft(order = false) {
        var left = 1000;
        if (this.textContent != null) {
            left = Math.min(left, this.textContent.x + this.getTextEdgeInsets().left)
        }
        if (order && this.orderContent != null) {
            left = Math.min(left, this.orderContent.x + this.getTextEdgeInsets().left)
        }
        if (this.generalizationContent != null) {
            left = Math.min(left, this.generalizationContent.x + this.getTextEdgeInsets().left)
        }
        if (this.imageContent != null) {
            left = Math.min(left, this.imageContent.x)
        }
        if (this.iconElementContents != null && this.iconElementContents.length > 0) {
            left = Math.min(left, this.iconElementContents[0].x)
        }
        if (this.taskContent != null) {
            left = Math.min(left, this.taskContent.x)
        }
        if (this.resourceContent != null) {
            left = Math.min(left, this.resourceContent.x)
        }
        if (this.moreContent != null) {
            left = Math.min(left, this.moreContent.x)
        }
        return left;
    }

    getPadingTop(isSignoreTitle = false) {
        var top = 1000;
        if (!isSignoreTitle && this.titleContent != null) {
            top = Math.min(top, this.titleContent.y)
        }
        if (this.textContent != null) {
            top = Math.min(top, this.textContent.y)
        }
        if (this.generalizationContent != null) {
            top = Math.min(top, this.generalizationContent.y)
        }
        if (this.imageContent != null) {
            top = Math.min(top, this.imageContent.y)
        }
        if (this.iconElementContents != null && this.iconElementContents.length > 0) {
            top = Math.min(top, this.iconElementContents[0].y)
        }
        if (this.taskContent != null) {
            top = Math.min(top, this.taskContent.y)
        }
        if (this.resourceContent != null) {
            top = Math.min(top, this.resourceContent.y)
        }
        if (this.moreContent != null) {
            top = Math.min(top, this.moreContent.y)
        }
        return top;
    }

    getPadingBottom() {
        var bottom = -1000;
        if (this.textContent != null) {
            bottom = Math.max(bottom, this.textContent.y + this.textContent.height - this.getTextEdgeInsets().top - this.getTextEdgeInsets().bottom)
        }
        if (this.generalizationContent != null) {
            bottom = Math.max(bottom, this.generalizationContent.y + this.generalizationContent.height)
        }
        if (this.imageContent != null) {
            bottom = Math.max(bottom, this.imageContent.y + this.imageContent.height)
        }
        if (this.iconElementContents != null && this.iconElementContents.length > 0) {
            bottom = Math.max(bottom, this.iconElementContents[0].y + this.iconElementContents[0].height)
        }
        if (this.taskContent != null) {
            bottom = Math.max(bottom, this.taskContent.y + this.taskContent.height)
        }
        if (this.resourceContent != null) {
            bottom = Math.max(bottom, this.resourceContent.y + this.resourceContent.height)
        }
        if (this.moreContent != null) {
            bottom = Math.max(bottom, this.moreContent.y + this.moreContent.height)
        }
        return bottom;
    }

    getTextEdgeInsets() {
        switch (this.type) {
            case MindElementType.MAIN_SUBJECT:
                return Config.MainInputUIEdgeInsets;
            case MindElementType.SUBJECT:
                return Config.SubjectInputUIEdgeInsets;
            case MindElementType.CONTENT_GENERALIZATION:
                return Config.GeneralizationInputUIEdgeInsets;
            case MindElementType.SON_SUBJECT:
                return Config.SonSubjectInputUIEdgeInsets;
            case MindElementType.EXPLAIN:
                return Config.ExplainInputUIEdgeInsets;
            default:
                return Config.SubjectInputUIEdgeInsets;
        }
    }

    checkShapeCard(mindBGColor) {
        if (this.mindElementShape != MindElementShapeType.Card) {
            return
        }
        let textContent = this.titleContent
        if (textContent == null) {
            let text = new UiUtil().getString(strings.Mind_Edit_Title)
            textContent = new TextContent(text);
            this.alignmentType = MindElementAlignmentType.MIDDLE
            textContent.width = 112;
            textContent.height = 56;
            textContent.x = 0;
            textContent.y = 0;
            textContent.textBold = true;
            this.titleContent = textContent;

            if (this.textContent != null) {
                textContent.textFontSize = this.textContent.textFontSize + 4;
            } else if (this.generalizationContent != null) {
                textContent.textFontSize = this.generalizationContent.textFontSize + 4;
            } else {
                textContent.textFontSize = 20
            }
            if (Colors.isClear(this.borderColor)) {
                if (Colors.isClear(this.backgroundColor)) {
                    if (mindBGColor != 10000) {
                        if (Colors.isDarkColor(mindBGColor)) {
                            this.borderColor = Colors.white;
                        } else {
                            this.borderColor = Colors.black95;
                        }
                    }
                } else if (Colors.isDarkColor(this.backgroundColor)) {
                    this.borderColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.backgroundColor, 0.5));
                } else {
                    this.borderColor = Colors.black95;
                }
            } else if (this.borderColor == this.backgroundColor) {
                this.borderColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.backgroundColor, 0.5));
            }

            if (Colors.isClear(this.borderColor)) {
                if (Colors.isDarkColor(this.backgroundColor)) {
                    this.titleContent.textColor = Colors.white
                } else {
                    this.titleContent.textColor = Colors.black95
                }
            } else if (Colors.isDarkColor(this.borderColor)) {
                this.titleContent.textColor = Colors.white
            } else {
                this.titleContent.textColor = Colors.black95
            }
        }
        (MindElementCalculation.set(this)).caluleTextForData().calcule();
        this.checkSetTextColor(mindBGColor)
    }

    checkSetTextColor(mindBGColor) {
        if (this.textContent != null) {
            if (this.backgroundColorAlpha < 0.5) {
                if (mindBGColor != null) {                
                    if (Colors.isDarkColor(mindBGColor) && 
                        Colors.isDarkColor(this.textContent.textColor)) {
                            this.textContent.textColor = Colors.white
                    } else if (!Colors.isDarkColor(mindBGColor) && 
                        !Colors.isDarkColor(this.textContent.textColor)) {
                            if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                                this.textContent.textColor = Colors.black80
                            } else {
                                this.textContent.textColor = Colors.black95
                            }
                    }
                }
            } else {
                if (Colors.isClear(this.backgroundColor)) {
                    if (mindBGColor != null) {     
                        if (Colors.isDarkColor(mindBGColor) && 
                            Colors.isDarkColor(this.textContent.textColor)) {
                                this.textContent.textColor = Colors.white
                        } else if (!Colors.isDarkColor(mindBGColor) && 
                            !Colors.isDarkColor(this.textContent.textColor)) {
                                if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                                    this.textContent.textColor = Colors.black80
                                } else {
                                    this.textContent.textColor = Colors.black95
                                }
                        }
                    }                    
                } else if (Colors.isDarkColor(this.backgroundColor) && 
                    Colors.isDarkColor(this.textContent.textColor)) {
                        this.textContent.textColor = Colors.white
                } else if (!Colors.isDarkColor(this.backgroundColor) && 
                    !Colors.isDarkColor(this.textContent.textColor)) {
                        if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                            this.textContent.textColor = Colors.black80
                        } else {
                            this.textContent.textColor = Colors.black95
                        }
                }
            }
        } else if (this.generalizationContent != null) {
            if (this.backgroundColorAlpha < 0.5) {
                if (mindBGColor != null) {    
                    if (Colors.isDarkColor(mindBGColor) && 
                        Colors.isDarkColor(this.generalizationContent.textColor)) {
                            this.generalizationContent.textColor = Colors.white
                    } else if (!Colors.isDarkColor(mindBGColor) && 
                        !Colors.isDarkColor(this.generalizationContent.textColor)) {
                            if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                                this.generalizationContent.textColor = Colors.black80
                            } else {
                                this.generalizationContent.textColor = Colors.black95
                            }
                    }
                }                
            } else {
                if (Colors.isClear(this.backgroundColor)) {
                    if (mindBGColor != null) {    
                        if (Colors.isDarkColor(mindBGColor) && 
                            Colors.isDarkColor(this.generalizationContent.textColor)) {
                                this.generalizationContent.textColor = Colors.white
                        } else if (!Colors.isDarkColor(mindBGColor) && 
                            !Colors.isDarkColor(this.generalizationContent.textColor)) {
                                if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                                    this.generalizationContent.textColor = Colors.black80
                                } else {
                                    this.generalizationContent.textColor = Colors.black95
                                }
                        }
                    }                    
                } else if (Colors.isDarkColor(this.backgroundColor) && 
                    Colors.isDarkColor(this.generalizationContent.textColor)) {
                        this.generalizationContent.textColor = Colors.white
                } else if (!Colors.isDarkColor(this.backgroundColor) && 
                    !Colors.isDarkColor(this.generalizationContent.textColor)) {
                        if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                            this.generalizationContent.textColor = Colors.black80
                        } else {
                            this.generalizationContent.textColor = Colors.black95
                        }
                }
            }
        }

        if (this.titleContent != null) {
            if (Colors.isClear(this.borderColor)) {
                if (this.backgroundColorAlpha < 0.5) {
                    if (mindBGColor != null) {
                        if (Colors.isDarkColor(mindBGColor) && 
                            Colors.isDarkColor(this.titleContent.textColor)) {
                                this.titleContent.textColor = Colors.white
                        } else if (!Colors.isDarkColor(mindBGColor) && 
                            !Colors.isDarkColor(this.titleContent.textColor)) {
                                if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                                    this.titleContent.textColor = Colors.black80
                                } else {
                                    this.titleContent.textColor = Colors.black95
                                }
                        }
                    }                    
                } else {
                    if (Colors.isClear(this.backgroundColor)) {
                        if (mindBGColor != null) {
                            if (Colors.isDarkColor(mindBGColor) && 
                                Colors.isDarkColor(this.titleContent.textColor)) {
                                    this.titleContent.textColor = Colors.white
                            } else if (!Colors.isDarkColor(mindBGColor) && 
                                !Colors.isDarkColor(this.titleContent.textColor)) {
                                    if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                                        this.titleContent.textColor = Colors.black80
                                    } else {
                                        this.titleContent.textColor = Colors.black95
                                    }
                            }
                        }
                    } else if (Colors.isDarkColor(this.backgroundColor) && 
                        Colors.isDarkColor(this.titleContent.textColor)) {
                            this.titleContent.textColor = Colors.white
                    } else if (!Colors.isDarkColor(this.backgroundColor) && 
                        !Colors.isDarkColor(this.titleContent.textColor)) {
                            if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                                this.titleContent.textColor = Colors.black80
                            } else {
                                this.titleContent.textColor = Colors.black95
                            }
                    }
                }
            } else if (Colors.isDarkColor(this.borderColor) && 
                Colors.isDarkColor(this.titleContent.textColor)) {
                    this.titleContent.textColor = Colors.white
            } else if (!Colors.isDarkColor(this.borderColor) && 
                !Colors.isDarkColor(this.titleContent.textColor)) {
                    if (this.type == MindElementType.SON_SUBJECT || this.type == MindElementType.EXPLAIN) {
                        this.titleContent.textColor = Colors.black80
                    } else {
                        this.titleContent.textColor = Colors.black95
                    }
            }
        }
    }

    getNodeExplainFontSize() {
        let textFontSize = Config.NodeExplainFontSize;
        if (this.textContent != null) {
            textFontSize = this.textContent.textFontSize;
        } else if (this.generalizationContent != null) {
            textFontSize = this.generalizationContent.textFontSize;
        }
        let fontSize = (textFontSize * 0.7);
        if (fontSize < 10) {
            return 10;
        } else {
            return Math.min(Config.NodeExplainFontSize, fontSize);
        }
    }

    isDisplayBorder() {
        return !Colors.isClear(this.borderColor) && this.borderWidth > 0
    }

    isBackgroundAndBorder() {
        return (this.borderWidth > 0 && !Colors.isClear(this.borderColor)) || !Colors.isClear(this.backgroundColor);
    }

    isLeft(point) {
        return point.x <= this.x;
    }

    isRight(point) {
        return point.x >= this.x + this.width;
    }

    isTop(point) {
        return point.y <= this.y;
    }

    isBottom(point) {
        return point.y >= this.y + this.height;
    }
}



export default MindElementData

import Point from "../../../../viewmodel/core/base/Point"
import Size from "../../../../viewmodel/core/base/Size"
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import MindElementData from "../../../../viewmodel/mindelementdata/MindElementData"
import IconElementContent from "../../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import MindElementContent from "../../../../viewmodel/mindelementdata/mindcontent/MindElementContent"
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndCheckBoxCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return;
        }
        
        let size = this.includeCheckBoxAndTextSize();
        let checkBoxSize = this.caluleCheckBoxSize();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;
        let textMargin = this.getTextEdgeInsets()
        let iconPointOffsetX = textMargin.left;
        let iconPointOffsetY = textMargin.top;
        if (textMindElementContent.text.length == 0 && !this.data.isEdit) {
            iconPointOffsetX = 0;
            iconPointOffsetY = 0;
        }
        let checkBoxContent = this.data.checkBoxContent;
        if (checkBoxContent == null) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }

        let iconLeft = this.iconMargin + (this.data.type == MindElementType.MAIN_SUBJECT ? iconPointOffsetX * 0.75 : iconPointOffsetX * 0.5);
        let iconTop = (size.getHeight() - checkBoxSize.getHeight()) / 2;
        
        textMindElementContent.x = (checkBoxSize.getWidth() + this.checkboxTextSpace + this.iconMargin) + marginX;
        textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;        
        
        checkBoxContent.x = iconLeft + marginX;
        checkBoxContent.y = iconTop + marginY;
        
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}

export default TextAndCheckBoxCalculation
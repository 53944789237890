let MindElementType = {
    UNKNOWN:0,
    LINE:1,
    SON_LINE:2,
    CURVE:3,
    SUBJECT:4,
    MAIN_SUBJECT:5,
    QUIT:6,
    MOVE:7,
    ADD:8,
    SON_SUBJECT:9,
    LEFTBRACELINE:10, //摘要扣号方向向左
    CONTENT_GENERALIZATION:11,
    IMAGE:12,
    STRAIGHT_LINE:13, //直线
    CURVE_LINE:14,  //二次弧度线
    CURVE_LINE_2:15,  //二次弧度线,接入主题
    RIGHT_ANGLE_LINE:16, //直角
    RIGHT_ANGLE_CORNER_LINE:17, //直圆角

    LAYOUT_RIGHT:18, //右布局
    LAYOUT_BOTTOM:19, //底部布局

    SAVE_PNG:20, //保存图片
    FREE_NODE:21, //自由节点
    NODE_CONNECT_LINE:22, //节点连接
    BAOWEI_VIEW:23, //节点包裹起来

    LAYOUT_LEFT:24, //右布局
    LAYOUT_LEFT_RIGHT:25, //底部布局

    LAYOUT_VERTICAL:26, //底部布局
    LAYOUT_HORIZONTAL:27, //底部布局
    TIME_LINE_BASE_ELEMENT:28, //时间线布局底线
    MORE:29, //右上角更多
    ADD_BROTHER_TOP:30,
    ADD_BROTHER_BOTTOM:31,

    TIME_LINE_CIRCULAR:32, //线条节点圆形
    TIME_LINE_DIAMOND:33, //线条节点菱形

    SAVE_WATERMARK_PNG:34, //保存图片
    SAVE_PDF:35, //保存图片
    SAVE_WATERMARK_PDF:36, //保存图片
    OPEN_UPGRADE:37,
    ADD_ICON:38,
    SHARED_IMAGE:39,
    MIND_HELP:40,
    LAYOUT_BUBBLE:41,
    SHARED_ORIGINAL_DOCUMENT:42,
    BACKWARD_RETREAT:43,
    FORWARD:44,
    ADD_LINK:45,
    ADD_REMARKS:46,
    SAVE_ICLOUD:47,
    OUTLINE:48,
    OUTLINE_MAKE_LINE:49,
    DOWN_KEYBOARD:50,
    NODE_MOVE_BACKWARD:51,
    NODE_MOVE_FORWARD:52,
    NODE_DELETE:53,
    NODE_BOLD_STYLE:54,
    NODE_ITALICS_STYLE:55,
    NODE_STRIKETHROUGH_STYLE:56,
    NODE_OUTLINE_COLOR_STYLE:57,
    NODE_OUTLINE_CONTENT_GENERALIZATION_STYLE:58,
    SHARED_MARKDOWN_DOCUMENT:59,
    OPEN_STYLE:60,
    SET_STYLE:61,
    MAP_CONNECT_LINE:62, //导图连接
    MAP_SPLIT:63, //导图拆分
    FULL_RIGHT_ANGLE_CORNER_LINE:64, //直圆角
    LAYOUT_HORIZONTAL_S:65, //时间轴横向S行
    TIME_LINE_CIRCULAR_CONNECT_HEAD:66, //线条节点连接时间
    TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE:67, //线条节点连接时间和Title
    TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER:68, //线条节点连接时间带序号
    TIME_LINE_CIRCULAR_ORDER:69, //线条节点圆形带序号
    TIME_LINE_CIRCULAR_ARROW_HEAD:70, //线条节点连接时间以箭头形式
    LAYOUT_FORM:71, //表格布局
    FORM_LINE:72,    //表格分割线
    NODE_CONNECT_LINE_ARROW:73, //节点连接箭头
    NODE_CONNECT_LINE_CIRCULAR:74, //节点连接圆头
    NODE_CONNECT_LINE_CURVE:75, //节点连接曲线
    NODE_CONNECT_LINE_RIGHT_ANGLE:76, //节点连接直角线
    COPY_ALL_TEXT:77, //k复制所有文字
    RIGHT_ANGLE_CORNER_ARROW_LINE:78, //直圆角箭头
    FULL_RIGHT_ANGLE_CORNER_ARROW_LINE:79, //中心直线箭头
    LAYOUT_TREE_RIGHT:80, //右树布局
    LAYOUT_TREE_LEFT:81, //左树布局
    LAYOUT_TREE_LEFT_RIGHT:82, //左树布局
    STRAIGHT_LINE_2:86, //直线
    MIND_INSERT:87,   //插入
    LAYOUT_TRIANGLE: 89,
    ADD_MATERIAL: 90, //素材库 MATERIAL_LIBRARY
    RECORD_AUDIO: 91, //录音
    LAYOUT_BRACKETS_RIGHT: 92, //扣号图

    PLAY_MINDMAP:93,
    PAUSE_MINDMAP:94,
    STOP_MINDMAP:95,
    TEXT_UP_KEYBOARD:96,
    TEXT_DOWN_KEYBOARD:97,
    CLEAR_TEXT_STYLE:98,
    TIME_LINE_CIRCULAR_ORDER_1:99, //线条节点圆形带序号
    TIME_LINE_HEXAGON_ORDER:100, //线条节点正六边形序号

    MIND_STYLE_ADD_DOODLE:101, //涂鸦与绘画

    LAYOUT_LADDER:102, //时间线梯形
    LAYOUT_HORIZONTAL_CRISSCROSS:103, //横向上线交错

    MIND_SHEET_PAGE:104, //分页
    SHARE_GROUP:105, //上传到组
    MIND_SEE:106, //查看
    LAYOUT_RIGHT_LEFT:107, //右侧从顶部开始，左侧从顶部开始
    LAYOUT_RIGHT_LEFT_CLOCKWISE:108, //左右布局，从右侧顶部开始,顺时针

    REPORT:109, //举报
    SAVE_GMAP:110, //保存
    EDIT_ACTION:111, //编辑

    EXIT_PLAY:112,  //退出播放

    LAYOUT_RADIATION_MAP:113, //圆环型布局
    RADIATION_MAP_LINE:114,    //环形布局背景
    LAYOUT_TOP:115, //顶部布局
    LAYOUT_TOP_TREE_RIGHT:116, //树顶部右布局
    LAYOUT_TOP_TREE_LEFT:117, //树顶部左布局
    LAYOUT_TOP_TREE_LEFT_RIGHT:118, //树顶部左右布局
    LAYOUT_HORIZONTAL_RIGHT:119, //横向布局
    LAYOUT_VERTICAL_RIGHT:120, //竖向布局
    LAYOUT_FISH_RIGHT:121, //右侧鱼骨图
    LAYOUT_FISH_RIGHT_LINE:122, //右侧鱼骨图
    CURVE_LINE_AVERAGE:123, //起点平分二次d弧度线
    CURVE_LINE_CIRCULAR:124,  //二次d弧度线-端点圆
    ADD_VIDEO:125,//上传视频相关
    LAYOUT_BRACKETS_LEFT:126,//括号图
    LATEX:127,//LATEX
    LAYOUT_CIRCLE:128,//圆圈图
    EXPLAIN:129, //解释
    ATTACHMENT:130, //附件
    SKELETON:131, //骨架
    LAYOUT_TIME_ARC:132, //弧形布局
    CUSTOM_VIEW:133,
    LAYOUT_FORM_HORIZONTAL:134, //表格布局
    LAYOUT_FREE_TREE:135, //四周自由树结构
    LAYOUT_BRIDGE:136, //桥型结构
    LAYOUT_RADIATE:137, //辐射图
    LAYOUT_CUSTOM_LINE:138, //自定义直线
    LAYOUT_FISH_LEFT:139, //左侧鱼骨图
    STRAIGHT_CURVE_LINE:140,
    
    CHECK_BOX:10001,
    
    from:function(value) {
        if (value == null) {
            return this.UNKNOWN;
        }
        if (typeof value == "string") {
            value = parseInt(value)
        }
        for(let mealName of Object.values(this)){
            if(mealName == value) {
                return value;
            }
        }
        return this.UNKNOWN;
    }

    
}
export default MindElementType
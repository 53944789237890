import Point from "../../../../viewmodel/core/base/Point"
import Size from "../../../../viewmodel/core/base/Size"
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import MindElementData from "../../../../viewmodel/mindelementdata/MindElementData"
import IconElementContent from "../../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import MindElementContent from "../../../../viewmodel/mindelementdata/mindcontent/MindElementContent"
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import TextAndCheckBoxCalculation from "./TextAndCheckBoxCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndMoreAndCheckBoxCalculation extends TextAndCheckBoxCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return;
        }
        let moreContent = this.data.moreContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = textRext.height();
        moreContent.width = textHeight;
        moreContent.height = textHeight;

        let size = this.includeTextAndCheckboxAndMoreSize();
        let marginPoint = this.getMargin(size);

        let checkBoxContent = this.data.checkBoxContent;

        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let textMargin = this.getTextEdgeInsets()
        let iconPointOffsetX = textMargin.left;
        let iconPointOffsetY = textMargin.top;
        
        let startLeft = this.iconMargin + (this.data.type == MindElementType.MAIN_SUBJECT ? iconPointOffsetX * 0.75 : iconPointOffsetX * 0.5);
        
        checkBoxContent.x = startLeft + marginX;
        checkBoxContent.y = (size.getHeight() - checkBoxContent.height) / 2 + marginY;

        textMindElementContent.x = checkBoxContent.width + this.checkboxTextSpace + this.iconMargin + marginX;
        textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;        
        
        moreContent.x = textMindElementContent.x + textMindElementContent.width;
        moreContent.y = ((size.getHeight()) - moreContent.height) / 2 + marginY;        

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndMoreAndCheckBoxCalculation